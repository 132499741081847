import React, {
  useState,
  useEffect,
  useRef,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import { UserPromptClosureCountdownPanel } from './UserPromptPanel';

import { ChatBody } from './ChatBody';
import { ChatHeader } from './ChatHeader';
import { ChatOpener } from './ChatOpener';
import { ChatInput } from './ChatInput';
import useStore from '../state';

import { getDialogConnector } from '../dialog-connectors/getDialogConnector';
import type {
  VoiceConfig,
  MChatEvents,
  MChatConfig,
  MChatTranslations,
  ChatInputActions,
  MChatActions,
  SessionValues,
  MChatComponentsCustomization,
  Avatar,
} from '../types';
import classNames from 'classnames';
import { AudioController } from './AudioController';
import i18n from '../i18n';
import utc from 'dayjs/plugin/utc';
import dayjs from 'dayjs';

dayjs.extend(utc);

export const MOBILE_BREAKPOINT = '700px';

interface ChatProps {
  integrationKey?: string;
  engine: string;
  baseUrl: string;
  accountId?: string;
  botId?: string;
  botVersionId?: string;
  customTranslations?: MChatTranslations;
  componentsCustomization?: MChatComponentsCustomization;
  sessionValues?: SessionValues;
  config?: MChatConfig;
  voiceConfig?: VoiceConfig;
  avatars?: Avatar[];
  onEvent?: (name: string, data: object) => void;
}

const MCHAT_AUDIO_TERMINATED_IMMEDIATELY: boolean =
  process.env.MCHAT_AUDIO_TERMINATED_IMMEDIATELY === 'true';
// const audioEnabled: boolean = process.env.MCHAT_AUDIO_ENABLED === 'true';

// eslint-disable-next-line react/display-name
export const Chat = forwardRef<MChatActions, ChatProps>(
  (
    {
      integrationKey,
      engine,
      baseUrl,
      accountId,
      botId,
      botVersionId,
      customTranslations,
      componentsCustomization,
      sessionValues,
      config,
      voiceConfig,
      avatars,
      onEvent = () => {},
    },
    ref,
  ) => {
    const isOpen = useStore((state) => state.widgetOpen);
    const openWidget = useStore((state) => state.actions.openWidget);
    const closeWidget = useStore((state) => state.actions.closeWidget);
    const stateSessionId = useStore((state) => state.sessionId);

    const [pushToActivateDisabled, disablePushToActivate] = useState(false);
    const [isScrollingDown, setisScrollingDown] = useState(false);

    const openUserPromptPanel = useStore((state) => state.openUserPromptPanel);
    // const widgetConfig = useStore((state) => state.config);
    const chatBlocks = useStore((state) => state.chatBlocks);
    const protocolManager = useStore((state) => state.protocolManager);

    const audioManager = useStore((state) => state.audioManager);
    const sendMessage = useStore((state) => state.actions.sendMessage);
    const updateConfig = useStore((state) => state.actions.updateConfig);

    const setAvatars = useStore((state) => state.actions.setAvatars);
    const setSessionValues = useStore(
      (state) => state.actions.setSessionValues,
    );
    const setComponentsCustomization = useStore(
      (state) => state.actions.setComponentsCustomization,
    );
    const connect = useStore((state) => state.actions.connect);
    const maxUserInputLength = useStore(
      (state) => state.config.maxUserInputLength,
    );

    const chatInputRef = useRef<ChatInputActions>(null);

    const handleOpen = useCallback(() => {
      openWidget();
      setisScrollingDown(false);
    }, [openWidget]);

    useImperativeHandle(
      ref,
      () => {
        return {
          open() {
            handleOpen();
          },
          setUserInput(text: string) {
            chatInputRef.current?.setUserInput(text);
          },
          focusInput() {
            chatInputRef.current?.focus();
          },
        };
      },
      [],
    );

    useEffect(() => {
      setAvatars(avatars);
    }, [avatars]);

    useEffect(() => {
      let enabled = true;
      const threshold = 0;
      let lastScrollY = window.scrollY;
      let ticking = false;

      const updateScrollDir = () => {
        const scrollY = window.scrollY;

        if (Math.abs(scrollY - lastScrollY) < threshold) {
          ticking = false;
          return;
        }

        let value = scrollY > lastScrollY

        if (!value && scrollY > 0) {
          enabled = false
          value = true
        } 

        setisScrollingDown(value);
        lastScrollY = scrollY > 0 ? scrollY : 0;
        ticking = false;
      };

      const onScroll = () => {
        if (window.scrollY === 0) {
          enabled = true
        }

        if (!enabled) return;
        
        if (!ticking) {
          window.requestAnimationFrame(updateScrollDir);
          ticking = true;
        }
      };

      window.addEventListener('scroll', onScroll);

      return () => {
        window.removeEventListener('scroll', onScroll);
      };
    }, [setisScrollingDown]);

    useEffect(() => {
      setSessionValues(sessionValues ?? {});
      setComponentsCustomization(componentsCustomization);
      if (customTranslations) {
        for (const [key, value] of Object.entries(customTranslations)) {
          i18n.addResourceBundle(key, 'translation', value, true, true);
        }
      }

      const handleBeforeUnload = (event: Event) => {
        if (window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT})`)?.matches) {
          closeWidget({ keepSession: true });
        }
      };
      window.addEventListener('beforeunload', handleBeforeUnload);
      return () => {
        window.removeEventListener('beforeunload', handleBeforeUnload);
      };
    }, []);

    useEffect(() => {
      updateConfig(config ?? {}, () =>
        getDialogConnector({
          integrationKey,
          baseUrl,
          mchannelsBotId: engine,
          accountId,
          botId,
          botVersionId,
        }),
      );
    }, [baseUrl, engine, accountId, botId, botVersionId, config]);

    useEffect(() => {
      if (isOpen) {
        void connect(true);
      }
    }, []);

    useEffect(() => {
      if (pushToActivateDisabled) {
        disablePushToActivate(false);

        if (MCHAT_AUDIO_TERMINATED_IMMEDIATELY) {
          audioManager.currentSession.terminate();
        }
      }
    }, [chatBlocks, pushToActivateDisabled]);

    const sendTextMessage = useCallback(
      (message: string) => {
        sendMessage(message);
        setTimeout(() => {
          chatInputRef.current?.focus();
        }, 20);
      },
      [sendMessage, chatInputRef.current],
    );

    const handleClose = useCallback(() => {
      closeWidget();
    }, [closeWidget]);

    const onMChatEvent = useCallback(
      (event: MChatEvents) => {
        const { name, ...rest } = event;
        onEvent(event.name, { ...rest });
      },
      [onEvent],
    );

    const renderUserPromptPanel = useCallback(() => {
      switch (openUserPromptPanel) {
        case 'UserPromptClosureCountdownPanel':
          return <UserPromptClosureCountdownPanel />;
      }
    }, [openUserPromptPanel]);

    const targetEnvironment = baseUrl.endsWith('/dev') ? 'dev' : 'prod';

    return (
      <div
        className={classNames('mchat-container', {
          'mchat-container--closed': !isOpen,
          'mchat-container--hidden': isScrollingDown && !isOpen,
        })}
      >
        {!isOpen ? (
          <ChatOpener onClick={handleOpen}>Open</ChatOpener>
        ) : (
          <div className='mchat'>
            <ChatHeader onClose={handleClose} />
            {renderUserPromptPanel() ?? (
              <ChatBody onEvent={onMChatEvent} chatBlocks={chatBlocks} />
            )}
            {!openUserPromptPanel && (
              <AudioController
                sessionId={
                  protocolManager?.sessionId ?? stateSessionId ?? undefined
                }
                targetEnvironment={targetEnvironment}
                extension={voiceConfig?.extension ?? ''}
              >
                {(audioState, onAudioButtonClick) => {
                  return (
                    <ChatInput
                      ref={chatInputRef}
                      audioState={voiceConfig ? audioState : undefined}
                      onAudioButtonClick={onAudioButtonClick}
                      onMessage={sendTextMessage}
                      maxCharacters={maxUserInputLength}
                    />
                  );
                }}
              </AudioController>
            )}
          </div>
        )}
      </div>
    );
  },
);
