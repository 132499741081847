import React, { type FC } from 'react';
import type {
  MChatEvents,
  HyperlinksGroupChatBlockContent,
  Direction,
} from '../../types';
import classNames from 'classnames';
import { HyperlinkButton } from '../common/HyperlinkButton';

interface HyperlinksGroupMessageProps extends HyperlinksGroupChatBlockContent {
  onEvent: (event: MChatEvents) => void;
  direction: Direction;
}

export const HyperlinksGroupMessage: FC<HyperlinksGroupMessageProps> = ({
  hyperlinks,
  direction,
  onEvent,
}) => {
  return (
    <div
      className={classNames('mchat-message', 'mchat-hyperlinks-group-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      {hyperlinks.map((hyperlink, index) => {
        const { onClickAction, url, text } = hyperlink;
        return (
          <HyperlinkButton
            key={`hyperlink-${index}`}
            url={url}
            onClickAction={onClickAction}
            onEvent={onEvent}
          >
            {text}
          </HyperlinkButton>
        );
      })}
    </div>
  );
};
