import robotInHole from '../assets/robot-in-hole.png';

export const ChatHeader = (props: any) => {
  return (
    <div className='mchat-header'>
      <button
        type='button'
        title='Close'
        onClick={() => {
          props.onClose();
        }}
        className='mchat-closer'
      >
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1.5em'
          height='1.5em'
          fill='none'
          stroke='currentColor'
          strokeWidth='0'
          viewBox='0 0 15 15'
        >
          <path
            fill='currentColor'
            fillRule='evenodd'
            d='M11.782 4.032a.575.575 0 10-.813-.814L7.5 6.687 4.032 3.218a.575.575 0 00-.814.814L6.687 7.5l-3.469 3.468a.575.575 0 00.814.814L7.5 8.313l3.469 3.469a.575.575 0 00.813-.814L8.313 7.5l3.469-3.468z'
            clipRule='evenodd'
          ></path>
        </svg>
      </button>
    </div>
  );
};
