import { type ActiveState, type HumanAgentsState } from '../../TransferBase';
import urljoin from 'url-join';
import isEqual from 'lodash.isequal';
import { TransferController, TransferSession } from '../../TransferBase';
import axios from 'axios';
import fetchJsonp from 'fetch-jsonp';

interface TransferData {
  title?: string;
  email?: string;
  phone?: string;
  messages?: Array<{
    text: string;
    type: string;
    direction: string;
  }>;
}

interface SessionData {
  chatName: string;
}

interface SessionConfig {
  pollingFrequency: number;
}

interface PollingResponse {
  result: {
    answered: boolean;
    userIsTyping: boolean;
    disconnection: string;
    users: Array<{
      title: string;
      icon: boolean;
    }>;
    messages: Array<{
      name: string;
      type: string;
      user?: string;
      direction: string;
      time_created: string;
      text: string;
    }>;
  };
}

interface SettingsResponse {
  error: any[];
  result: {
    state: string;
    inputs: object[];
    clientSettings: object;
  };
}

const DEFAULT_BASE_URL = 'https://robotworld.daktela.com';
const DEFAULT_API_BASE = 'external/web/api';

export class DactelaTransferController extends TransferController {
  private readonly _baseUrl: string;
  private readonly _apiBase: string;
  private readonly _accessToken: string;

  constructor(
    acecessToken: string,
    config: {
      baseUrl?: string;
      apiBase?: string;
    } = {},
  ) {
    super();

    this._accessToken = acecessToken;
    this._baseUrl = config.baseUrl ?? DEFAULT_BASE_URL;
    this._apiBase = config.apiBase ?? DEFAULT_API_BASE;
  }

  async transfer(data: TransferData): Promise<TransferSession> {
    const promise = new Promise<TransferSession>((resolve, reject) => {
      const response = fetchJsonp(this._assembleUrlForNewConversation(data));

      response
        .then(async (response) => {
          // eslint-disable-next-line @typescript-eslint/return-await
          return await response.json();
        })
        .then(
          (response: {
            result: {
              name: string;
              error: any[];
            };
          }) => {
            const { result } = response;
            if (!result) {
              reject(new Error('No result received.'));
            }

            const name = result?.name;
            const error = result?.error;

            if (error?.length > 0) {
              reject(new Error(error.toString()));
            }

            if (!name) {
              reject(new Error('No chatName received'));
            }

            this.transferSession = new DaktelaTransferSession(
              this._baseUrl,
              this._apiBase,
              this._accessToken,
              {
                chatName: name,
              },
              {
                pollingFrequency: 5000,
              },
            );
            resolve(this.transferSession);
          },
        )
        .catch((error: object) => {
          console.error(error);
          reject(new Error(JSON.stringify(error)));
        });
    });

    return await promise;
  }

  async verifyHumanAgentsAvailability(): Promise<HumanAgentsState> {
    const promise = new Promise<HumanAgentsState>((resolve, reject) => {
      fetchJsonp(this._assembleUrlForSettings())
        .then(async (response) => {
          // eslint-disable-next-line @typescript-eslint/return-await
          return await response.json();
        })
        .then((data: SettingsResponse) => {
          if (data.error.length > 0) {
            resolve({
              available: false,
            });
          }
          resolve({
            available: data.result.state === 'ONLINE',
          });
        })
        .catch((error: any) => {
          reject(error);
        });
    });
    return await promise;
  }

  _assembleUrlForSettings() {
    return urljoin(
      this._baseUrl,
      this._apiBase,
      'settings.jsonp',
      `?accessToken=${this._accessToken}`,
    );
  }

  _assembleUrlForNewConversation(data: TransferData) {
    const inputs = Object.entries(data).map((item) => {
      if (!item[1]) {
        return undefined;
      }

      if (item[0] === 'messages') {
        let messages = '';
        for (let i = 0; i < item[1].length; i++) {
          const value: TransferData['messages'] = item[1];
          const type = 'PUBLIC';
          const direction = 'IN';

          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const text = `${value![i].direction === 'in' ? 'B' : 'H'}: ${
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            value![i].text
          }`;
          messages += `&messages[${i}][type]=${type}&messages[${i}][direction]=${direction}&messages[${i}][text]=${encodeURIComponent(
            text,
          )}`;
        }
        return messages;
      } else {
        const value: string = item[1];
        return `&inputs[${item[0]}]=${value}`;
      }
    });

    const filteredInputs: string[] = inputs.filter(
      (item?: string): item is string => typeof item !== 'undefined',
    );

    return urljoin(
      this._baseUrl,
      this._apiBase,
      'newWebchat.jsonp',
      `?accessToken=${this._accessToken}`,
      ...filteredInputs,
    );
  }
}

export class DaktelaTransferSession extends TransferSession {
  private readonly _baseUrl: string;
  private readonly _apiBase: string;
  private readonly _accessToken: string;
  private readonly _axiosInstance: any;
  private readonly _sessionData: SessionData;
  private readonly _sessionConfig: SessionConfig;
  private _lastActiveState?: ActiveState;
  private _intervalId?: ReturnType<typeof setInterval>;

  constructor(
    _baseUrl: string,
    _apiBase: string,
    accessToken: string,
    sessionData: SessionData,
    sessionConfig: SessionConfig,
  ) {
    super();

    this._accessToken = accessToken;
    this._baseUrl = _baseUrl;
    this._apiBase = _apiBase;
    this._sessionData = sessionData;
    this._sessionConfig = sessionConfig;

    this.sessionState = {
      state: 'intiated',
    };

    this._axiosInstance = axios.create({
      baseURL: this._baseUrl,
    });
    this._setPolling();
  }

  async send(message: string): Promise<boolean> {
    const chatMessageBaseUrl = this._assembleBaseUrlForAction('sendMessage');
    const promise = new Promise<boolean>((resolve, reject) => {
      fetchJsonp(
        chatMessageBaseUrl +
          '&' +
          new URLSearchParams({
            text: message,
            type: 'PUBLIC',
            direction: 'IN',
          }).toString(),
      )
        .then((respnse) => {
          resolve(true);
        })
        .catch(async (error) => {
          reject(error);
        });
    });
    return await promise;
  }

  _setPolling() {
    const chatMessageBaseUrl = this._assembleBaseUrlForAction('webPullData');
    let messagesLength = 0;

    this._intervalId = setInterval(() => {
      void (async () => {
        try {
          const res = await fetchJsonp(chatMessageBaseUrl);
          const jsonResponse: PollingResponse = await res.json();

          const stateType = this.sessionState?.state;

          const messages = jsonResponse.result.messages.filter(
            (message) => message.direction !== 'IN',
          );

          if (messages.length > 0) {
            const messagesToAdd = messages
              .slice(messagesLength)
              .map((message) => {

                if (
                  message.type === 'PUBLIC' &&
                  message.text.includes("se připojil/a k chatu")
                ) {
                  message.text = `Operátor ${message.text}`;
                }

                return {
                  text: message.text,
                  user: message.user
                    ? {
                        gender: 'female',
                      }
                    : undefined,
                };
              });
            messagesLength = messages.length;
            this.emit('response', messagesToAdd);
          }

          if (jsonResponse.result.disconnection.length > 0) {
            this.sessionState = {
              state: 'ended',
            };
            this.emit('stateUpdate', this.sessionState);
            clearInterval(this._intervalId);
            return;
          }

          switch (stateType) {
            case 'intiated':
              if (jsonResponse.result.answered) {
                this.sessionState = {
                  state: 'acknowledged',
                };
                this.emit('stateUpdate', this.sessionState);
                this.sessionState = {
                  state: 'active',
                  humanAgentTyping: jsonResponse.result.userIsTyping,
                };
                this.emit('stateUpdate', this.sessionState);
                this._lastActiveState = this.sessionState;
              }
              break;
            case 'active': {
              const newState: ActiveState = {
                state: 'active',
                humanAgentTyping: jsonResponse.result.userIsTyping,
              };
              if (this._hasActiveStateChanged(newState)) {
                this.sessionState = newState;
                this.emit('stateUpdate', this.sessionState);
              }
              this._lastActiveState = newState;
              break;
            }
          }
        } catch (error) {
          console.error(error);
        }
      })();
    }, this._sessionConfig.pollingFrequency);
  }

  close(): void {
    if (this.sessionState?.state !== 'ended') {
      this.sessionState = {
        state: 'ended',
      };
      this.emit('stateUpdate', this.sessionState);
      clearInterval(this._intervalId);
    }
  }

  _createActiveStateBasedOnResponse(response: PollingResponse): ActiveState {
    return {
      state: 'active',
      humanAgentTyping: response.result.userIsTyping,
    };
  }

  _hasActiveStateChanged(state: ActiveState) {
    return isEqual(this._lastActiveState, state);
  }

  _assembleBaseUrlForAction(action: 'sendMessage' | 'webPullData') {
    return (
      urljoin(
        this._baseUrl,
        this._apiBase,
        action,
        `${this._sessionData.chatName}.jsonp?`,
      ) +
      new URLSearchParams({
        accessToken: this._accessToken,
      }).toString()
    );
  }
}
