import React, { type FC, type ReactNode, forwardRef } from 'react';

import { type IconDefinition } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons/faArrowUpRightFromSquare';
import classNames from 'classnames';

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: ReactNode;
  className?: string;
  selected?: boolean;
}

export interface LinkButtonProps
  extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: ReactNode;
  url?: string;
  className?: string;
}

interface IconButtonProps extends ButtonProps {
  icon: IconDefinition;
}

interface OptionButtonProps extends ButtonProps {
  selected: boolean;
}

export const Button = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ children, selected = false, className, ...rest }, ref) => {
    const classes = classNames('mchat-button', className, {
      'mchat-button--selected': selected,
    });
    return (
      <button {...rest} ref={ref} className={classes}>
        {children}
      </button>
    );
  },
);
Button.displayName = 'Button';

export const IconButton: FC<IconButtonProps> = ({
  children,
  icon,
  ...rest
}) => {
  return (
    <Button {...rest} className='mchat-icon-button'>
      <>
        <span>{children}</span>
        <FontAwesomeIcon icon={icon} />
      </>
    </Button>
  );
};

export const OptionButton: FC<OptionButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  const classes = classNames('mchat-option-button', className);
  return (
    <Button {...rest} className={classes}>
      {children}
    </Button>
  );
};

export const LinkButton: FC<LinkButtonProps> = ({
  children,
  className,
  url,
  ...rest
}) => {
  const classes = classNames(
    'mchat-button',
    'mchat-icon-button',
    'mchat-hyperlink-button',
    className,
  );
  return (
    <a className={classes} href={url} {...rest}>
      <span>{children}</span>
      <FontAwesomeIcon icon={faArrowUpRightFromSquare} />
    </a>
  );
};
