import React from 'react';
import { type FC } from 'react';
import {
  type MChatEvents,
  type HyperlinkChatBlockContent,
  type Direction,
} from '../../types';

import classNames from 'classnames';
import { HyperlinkButton } from '../common/HyperlinkButton';

interface HyperlinkMessageProps extends HyperlinkChatBlockContent {
  onEvent?: (event: MChatEvents) => void;
  direction: Direction;
}

export const HyperlinkMessage: FC<HyperlinkMessageProps> = ({
  url,
  text,
  direction,
  ...rest
}) => {
  return (
    <div
      className={classNames('mchat-message', 'mchat-hyperlink-message', {
        'mchat-message--in': direction === 'in',
        'mchat-message--out': direction === 'out',
      })}
    >
      <HyperlinkButton url={url} {...rest}>
        {text}
      </HyperlinkButton>
    </div>
  );
};
