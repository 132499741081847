// © 2023 MAMA TELMA AI s.r.o. Všechna práva vyhrazena.
//
// Tento kód je duševní vlastnictví společnosti MAMA TELMA AI s.r.o.
// Jestliže nemáte schválení společnosti MAMA TELMA AI s.r.o., není vám dovoleno kód upravovat,
// kopírovat nebo provádět jiné akce s tímto kódem bez předchozího písemného souhlasu společnosti MAMA TELMA AI s.r.o.
//
// Společnost MAMA TELMA AI s.r.o. si vyhrazuje veškerá práva k dispozici v rámci platného autorského práva,
// včetně všech případných soudních nároků a zákonných ustanovení týkajících se tohoto kódu.
//
// Jakákoli neoprávněná osoba nebo subjekt odhalující, kopírující, distribuující nebo používající tento
// kód bez souhlasu společnosti MAMA TELMA AI s.r.o. se vystavuje právním následkům, včetně žalob a nároků na náhradu škody.

// Společnost MAMA TELMA AI s.r.o. si vyhrazuje právo kdykoli aktualizovat nebo změnit tuto licenční hlavičku.
// Doporučuje se pravidelně zkontrolovat tuto hlavičku a dodržovat její nejnovější verzi.
// Pro více informací kontaktujte společnost MAMA TELMA AI s.r.o. na adrese info@telma.ai.

import { Chat } from './../components/Chat';
import { createRoot } from 'react-dom/client';

import robotAvatar from '../assets/avatars/robot.png';
import femaleAvatar from '../assets/avatars/female.png';

import type {
  MChatTranslations,
  MChatConfig,
  VoiceConfig,
  MChatActions,
  SessionValues,
  MChatComponentsCustomization,
  Avatar,
} from './../types';

interface MChatInstance extends MChatActions {}

interface RenderParams {
  integrationKey?: string;
  containerId: string;
  baseUrl: string;
  engine: string;
  customTranslations?: MChatTranslations;
  componentsCustomization?: MChatComponentsCustomization;
  sessionValues?: SessionValues;
  config?: MChatConfig;
  voiceConfig?: VoiceConfig;
  avatars?: Avatar[];
  accountId: string;
  botId: string;
  botVersionId: string;
}

const render = (
  {
    containerId,
    integrationKey,
    baseUrl,
    engine,
    customTranslations,
    componentsCustomization,
    sessionValues,
    avatars,
    config,
    voiceConfig,
    accountId,
    botId,
    botVersionId,
  }: RenderParams,
  callback: (mChatInstance: MChatInstance | null) => void = () => {},
) => {
  const domNode = document.getElementById(containerId);
  // @ts-expect-error dom node will be always there
  const root = createRoot(domNode);

  avatars = [
    {
      type: 'robot',
      source: robotAvatar,
      scope: 'bot_message',
      position: 'start',
      width: '1.2rem',
    },
    {
      type: 'human',
      source: femaleAvatar,
      scope: 'bot_message',
      position: 'start',
      width: '1.2rem',
    },
  ];

  const configuration = {
    language: 'cs',
    userInactivityTimeout: 16000,
    ...(config ?? {}),
  };

  root.render(
    <Chat
      ref={(mChatActions: MChatActions) => {
        const mChatInstance = {
          ...mChatActions,
        };

        callback(mChatInstance);
      }}
      integrationKey={integrationKey}
      engine={engine}
      baseUrl={baseUrl}
      accountId={accountId}
      botId={botId}
      botVersionId={botVersionId}
      customTranslations={customTranslations}
      componentsCustomization={componentsCustomization}
      sessionValues={sessionValues}
      avatars={avatars}
      config={configuration}
      voiceConfig={voiceConfig}
    ></Chat>,
  );
};

export { render };
