import MChannelsProtocolMchatAdapter from '../protocol-mchat-adapters/MChannelsProtocolMchatAdapter';
import type { DialogConnector } from '../dialog-connectors/DialogConnector';
import { type ProtocolCommands, ProtocolManager } from './ProtocolManager';
import { HTMLTextPreprocessor } from '../utils/TextPreprocessor';
import MChannelsRestDialogConnector from '../dialog-connectors/MChannelsRestDialogConnector';
import { type FeedbackInputFormat } from '../types';

interface mChannelsRequest {
  input: {
    text?: string;
    event?: string;
    feedback?: {
      rating?: number | 'up' | 'down';
      comment?: {
        text: string;
      };
    };
  };
  context: object;
  output: object;
}

interface mChannelsResponse {
  input: {
    text?: string;
    event?: string;
  };
  context: {
    session_id: string;
    conversation_id: string;
    action?: {
      command: 'Transfer' | 'ProceedWithTransfer';
      params?: object;
    };
  };
  output: object;
}

class MChannelsProtocolManager extends ProtocolManager {
  context: object;
  eventToSend: string | undefined;

  constructor(dialogConnector: DialogConnector) {
    super(
      dialogConnector,
      new MChannelsProtocolMchatAdapter(new HTMLTextPreprocessor()),
    );

    this.context = {};

    this.eventToSend = undefined;
  }

  send(
    inputText: string,
    props: {
      extraPayload?: object;
      extraInput?: object;
    } = {},
  ) {
    const { extraPayload = {}, extraInput = {} } = props;
    const payload: mChannelsRequest = {
      input: {
        text: inputText,
        ...extraInput,
      },
      context: this.context,
      output: {},
      ...extraPayload,
    };

    if (typeof this.eventToSend !== 'undefined') {
      payload.input.event = this.eventToSend;
    }

    this._dialogConnector.send(payload);
  }

  sendFeedback(feedbackResponse: FeedbackInputFormat) {
    const payload: mChannelsRequest = {
      input: {
        feedback: feedbackResponse,
      },
      context: this.context,
      output: {},
    };

    if (typeof this.eventToSend !== 'undefined') {
      payload.input.event = this.eventToSend;
    }

    this._dialogConnector.send(payload);
  }

  refreshSession() {
    this._dialogConnector.refreshSession();
  }

  _sendEvent(name: string, extraPayload = {}) {
    const payload = {
      input: {
        event: name,
      },
      context: this.context,
      output: {},
      ...extraPayload,
    };

    this._dialogConnector.send(payload);
  }

  getCommandProcessors(...args: string[]): {
    [key in keyof typeof ProtocolCommands]: () => void;
  } {
    return {
      debug: () => {
        if (args[0] === 'on') {
          this.eventToSend = 'DebugOn';
        } else if (args[0] === 'off') {
          this.eventToSend = 'DebugOff';
        }
      },
    };
  }

  handleResponse(response: mChannelsResponse) {
    if (!response) {
      return;
    }

    this.context = response.context;

    if (typeof this.sessionId === 'undefined') {
      // TODO - As soon as Teresa start supporting the session_id do not use conversation_id as identifier
      if (this._dialogConnector instanceof MChannelsRestDialogConnector) {
        this.sessionId = response.context?.conversation_id;
      } else {
        this.sessionId = response.context?.session_id;
      }
    }

    const [mChatResponse, mChatInstructions] =
      this._protocolMchatAdapter.processResponse(response);

    this.emit('response', mChatResponse, mChatInstructions, {
      input: response.input?.text,
      event: response.input?.event,
    });

    if (response.context?.action?.command === 'Transfer') {
      this.emit('transfer', {
        name: 'transfer',
        command: 'verify_availability',
      });
    } else if (response.context?.action?.command === 'ProceedWithTransfer') {
      this.emit('transfer', {
        name: 'transfer',
        command: 'initiate',
        data: response.context?.action?.params,
      });
    }

    delete response.context.action;
  }

  reset() {
    this.context = {};
  }

  async reconnect(sessionId?: string) {
    if (this._dialogConnector instanceof MChannelsRestDialogConnector) {
      this.context = {
        conversation_id: sessionId,
      };
      return await this._dialogConnector.connect();
    } else {
      return await this._dialogConnector.connect(sessionId);
    }
  }

  _processDebugCommand(mode: 'on' | 'off') {}
}

export default MChannelsProtocolManager;
