import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import {
  AudioState,
  type TextChatBlock,
  type ChatBlock,
  type MChatConfig,
  type UserPromptPanelType,
  type MChatInstruction,
  type MChatTransferInstruction,
  type FeedbackInputFormat,
  type SessionValues,
  type MChatComponentsCustomization,
  type Avatar,
} from './types';
import {
  assembleChatBlocksFromUserErrorMessage,
  getMessageChatBlock,
  groupHyperlinks,
} from './helpers/ChatBlocks';
import type {
  ProtocolCommands,
  ProtocolManager,
} from './protocol-managers/ProtocolManager';
import type { DialogConnector } from './dialog-connectors/DialogConnector';
import MChannelsProtocolManager from './protocol-managers/MChannelsProtocolManager';
import { DactelaTransferController } from './modules/transfer/implementations/daktela/DaktelaTransferController';
import {
  type TransferController,
  type TransferSessionState,
} from './modules/transfer/TransferBase';

import i18n from './i18n';
import dayjs from 'dayjs';

const t = i18n.t;

const MIN_DELAY = 500;

const _streamedChatBlocks: Record<string, TextChatBlock> = {};

const getTransferController = (token: string): TransferController => {
  return new DactelaTransferController(token);
};

const useStore = create<{
  config: MChatConfig;
  chatBlocks: ChatBlock[];
  waitingForResponse: boolean;
  typing: boolean;
  audioState: AudioState;
  widgetOpen: boolean;
  keepSession: boolean;
  openUserPromptPanel?: UserPromptPanelType;
  protocolManager: ProtocolManager;
  transferController: TransferController;
  transferSessionState?: TransferSessionState;
  audioManager: any;
  lastUserActivityTimestamp: number;
  sessionId: string | null;
  sessionValues: SessionValues;
  avatars?: Avatar[];
  componentsCustomization: MChatComponentsCustomization;
  dialogConnectorFactory?: () => DialogConnector | undefined;
  actions: {
    appendChatBlock: (chatBlock: ChatBlock) => void;
    connect: (reopen: boolean) => Promise<void>;
    updateConfig: (
      configUpdate: Partial<MChatConfig>,
      dialogConnectorFactory?: () => DialogConnector | undefined,
    ) => void;
    appendChatBlocks: (
      chatBlocks: ChatBlock[],
      waitingForResponse?: boolean,
    ) => void;
    handleResponse: (
      blocksToAdd: ChatBlock[],
      mChatInstructions: MChatInstruction[],
      extraPayload?: { input?: string; event?: string },
    ) => void;
    handleTransfer: (instruction: MChatTransferInstruction) => void;
    handleTransferSessionStateUpdate: (
      sesisonState: TransferSessionState,
    ) => void;
    setAudioManager: (audioManager: object) => void;
    setSessionValues: (sessionValues: SessionValues) => void;
    setAvatars: (avatars?: Avatar[]) => void;
    setComponentsCustomization: (
      componentsCustomization?: MChatComponentsCustomization,
    ) => void;
    sendMessage: (
      textualInput: string,
      notShown?: boolean,
      textToShow?: string,
      extraInput?: object,
      extraPayload?: object,
    ) => void;
    sendFeedback: (feedback: FeedbackInputFormat) => void;
    setAudioState: (audioState: AudioState) => void;
    openWidget: () => void;
    closeWidget: (props?: { keepSession?: boolean }) => void;
    refreshSession: () => void;
    processCommand: (name: string, ...args: string[]) => void;
    reset: () => void;
  };
}>(
  // @ts-expect-error TODO FIX
  persist(
    // @ts-expect-error TODO FIX
    (set, get) => {
      let waitingForResponseTimeout: ReturnType<typeof setTimeout> | undefined;
      let userInactivityCloseTimeout: ReturnType<typeof setTimeout> | undefined;
      let userInactivityWarnTimeout: ReturnType<typeof setTimeout> | undefined;
      let lastMessageTimestamp = 0;

      document.onvisibilitychange = () => {
        if (document.visibilityState === 'visible') {
          setupUserInactivityTimeouts();
        }
      };

      document.addEventListener('mchat:open', () => {
        get().actions.openWidget();
      });

      const processEvent = (event: string) => {
        switch (event) {
          case 'EndOfSpeech':
            set((state) => ({
              audioState: AudioState.Processing,
            }));
            break;
        }
      };

      const processMChatInstructions = (instructions: MChatInstruction[]) => {
        for (const instruction of instructions) {
          switch (instruction.name) {
            case 'finishTurn':
              set({
                waitingForResponse: false,
                typing: false,
              });
              break;
            case 'transfer': {
              switch (instruction.command) {
                case 'verify_availability': {
                  const transferController = getTransferController(
                    get().config.transferToken ?? 'PROVIE_VALID_TOKEN',
                  );
                  set({
                    transferController,
                  });
                  const promiseResult =
                    transferController.verifyHumanAgentsAvailability();
                  void promiseResult.then((humanAgentsState) => {
                    if (humanAgentsState.available) {
                      get().actions.sendMessage('', true, undefined, {
                        nlu: {
                          intents: [
                            {
                              intent: 'human_agents_available',
                              confidence: 1.0,
                            },
                          ],
                        },
                      });
                    } else {
                      get().actions.sendMessage('', true, undefined, {
                        nlu: {
                          intents: [
                            {
                              intent: 'human_agents_unavailable',
                              confidence: 1.0,
                            },
                          ],
                        },
                      });
                    }
                  });
                  break;
                }
                case 'initiate': {
                  void get()
                    .transferController.transfer({
                      ...instruction.data,
                      messages: get()
                        .chatBlocks.map((block) => ({
                          text: (block.content as any)?.text
                            ?.trim()
                            .replace('&nbsp', ''),
                          type: block.type,
                          direction: block.direction,
                        }))
                        .filter((x) => x.text),
                    })
                    .then((session) => {
                      session.on(
                        'stateUpdate',
                        get().actions.handleTransferSessionStateUpdate,
                      );
                      set((state) => ({
                        transferSessionState: session.sessionState,
                        config: {
                          ...state.config,
                          mode: 'liveChat',
                        },
                      }));

                      session.on('response', (messages) => {
                        const textChatBlocks = messages.map((message) => {
                          const messageChatBlock = getMessageChatBlock(
                            message.text,
                          );
                          if (message.user) {
                            if (get().avatars) {
                              const humanAvatar = get().avatars?.find(
                                (item) => item.type === 'human',
                              );
                              if (humanAvatar) {
                                messageChatBlock.avatar = {
                                  ...humanAvatar,
                                };
                              }
                            }
                          }

                          return messageChatBlock;
                        });
                        get().actions.handleResponse(textChatBlocks, []);
                      });
                    });
                  break;
                }
              }
              break;
            }
          }
        }
      };

      const _assignAvatars = (chatBlocks: ChatBlock[]): ChatBlock[] => {
        const avatars = get().avatars;
        if (!avatars) {
          return chatBlocks;
        }

        const botMessageAvatar = avatars.find(
          (item) => item.scope === 'bot_message',
        );
        const userMessageAvatar = avatars.find(
          (item) => item.scope === 'user_message',
        );

        for (const chatBlock of chatBlocks) {
          if (chatBlock.type === 'message') {
            if (
              !chatBlock.avatar &&
              chatBlock.direction === 'in' &&
              botMessageAvatar
            ) {
              chatBlock.avatar = {
                ...botMessageAvatar,
              };
            } else if (userMessageAvatar) {
              chatBlock.avatar = {
                ...userMessageAvatar,
              };
            }
          }
        }
        return chatBlocks;
      };

      const _updateStreamedChatBlocks = (
        chatBlocks: ChatBlock[],
      ): ChatBlock[] => {
        const chatBlocksToRender = [];
        for (const chatBlock of chatBlocks) {
          if (chatBlock.type !== 'message') {
            chatBlocksToRender.push(chatBlock);
            continue;
          }

          const textChatBlock: TextChatBlock = chatBlock;

          if (!textChatBlock.streamed || !textChatBlock.id) {
            chatBlocksToRender.push(chatBlock);
            continue;
          }

          if (textChatBlock.id in _streamedChatBlocks) {
            _streamedChatBlocks[textChatBlock.id].content.text +=
              textChatBlock.content.text;
          } else {
            _streamedChatBlocks[textChatBlock.id] = chatBlock;
            chatBlocksToRender.push(chatBlock);
          }
        }
        return chatBlocksToRender;
      };

      const resetUserInactivityTimeout = () => {
        set((state) => ({
          lastUserActivityTimestamp: Date.now(),
        }));
        setupUserInactivityTimeouts();
      };

      const setupUserInactivityTimeouts = () => {
        if (userInactivityCloseTimeout) {
          clearTimeout(userInactivityCloseTimeout);
          userInactivityCloseTimeout = undefined;
        }

        if (userInactivityWarnTimeout) {
          clearTimeout(userInactivityWarnTimeout);
          userInactivityWarnTimeout = undefined;
        }

        const config = get().config;
        const time =
          (new Date().getTime() - get().lastUserActivityTimestamp) / 1000;

        const timeToClose = config.userInactivityTimeout - time;

        const timeToWarn =
          config.userInactivityTimeout -
          config.userInactivityCountdownTime -
          time;

        if (timeToClose < 0) {
          get().actions.closeWidget();
          return;
        }

        if (timeToWarn < 0) {
          set((state) => ({
            openUserPromptPanel: 'UserPromptClosureCountdownPanel',
          }));
        } else {
          userInactivityWarnTimeout = setTimeout(() => {
            set((state) => ({
              openUserPromptPanel: 'UserPromptClosureCountdownPanel',
            }));
          }, timeToWarn * 1000);
        }

        userInactivityCloseTimeout = setTimeout(() => {
          get().actions.closeWidget();
        }, timeToClose * 1000);
      };

      return {
        config: {
          userInactivityTimeout: 1200,
          userInactivityCountdownTime: 60,
          responseTimeout: 9000,
          mode: 'normal',
          maxUserInputLength: 120,
          skipInitialMessage: false,
        },
        sessionValues: {},
        chatBlocks: [],
        protocolManager: null,
        audioManager: null,
        audioState: AudioState.Active,
        waitingForResponse: true,
        widgetOpen: false,
        lastUserActivityTimestamp: Date.now(),
        sessionId: null,
        actions: {
          appendChatBlock: (chatBlock: ChatBlock) => {
            set((state) => ({
              chatBlocks: [...state.chatBlocks, chatBlock],
            }));
          },
          connect: async (reconnect: boolean = false): Promise<void> => {
            set((state) => ({
              openUserPromptPanel: undefined,
              typing: !reconnect && !state.config.skipInitialMessage,
              waitingForResponse:
                !reconnect && !state.config.skipInitialMessage,
            }));

            const config = get().config;
            const time =
              (new Date().getTime() - get().lastUserActivityTimestamp) / 1000;

            const timeToClose = config.userInactivityTimeout - time;

            if (timeToClose < 0) {
              reconnect = false;
            } else {
              resetUserInactivityTimeout();
            }

            const dialogConnectorFactory = get().dialogConnectorFactory;

            if (!dialogConnectorFactory) {
              return;
            }

            const dialogConnector = dialogConnectorFactory();

            if (!dialogConnector) {
              return;
            }

            const protocolManager = new MChannelsProtocolManager(
              dialogConnector,
            );

            set({ protocolManager });

            protocolManager.on(
              'response',
              (response, instructions, extraPayload) => {
                get().actions.handleResponse(
                  response,
                  instructions,
                  extraPayload,
                );
                set({
                  sessionId: protocolManager.sessionId,
                });
              },
            );

            protocolManager.on('transfer', (instruction) => {
              get().actions.handleTransfer(instruction);
            });

            if (!reconnect) {
              get().actions.reset();
            }

            try {
              if (reconnect) {
                await protocolManager.reconnect(get().sessionId ?? '');
                set({ waitingForResponse: false });
              } else {
                await protocolManager.connect();
                if (!get().config.skipInitialMessage) {
                  get().actions.sendMessage('', true, undefined, undefined);
                }
              }
            } catch (e) {
              console.error(e);
              set((state) => ({
                typing: false,
              }));
              get().actions.appendChatBlocks(
                assembleChatBlocksFromUserErrorMessage(
                  t('connection_error_user_message'),
                ),
                true,
              );
            }
          },
          updateConfig: (
            configUpdate: Partial<MChatConfig> = {},
            dialogConnectorFactory?: () => DialogConnector,
          ) => {
            set((state) => ({
              config: {
                ...state.config,
                ...configUpdate,
              },
              dialogConnectorFactory,
            }));

            if (configUpdate.language) {
              void i18n.changeLanguage(configUpdate.language);
            }
          },
          appendChatBlocks: (
            chatBlocks: ChatBlock[],
            waitingForResponse?: boolean,
          ) => {
            set((state) => ({
              chatBlocks: [...state.chatBlocks, ...chatBlocks],
              waitingForResponse,
            }));
          },
          handleTransfer: (instruction: MChatTransferInstruction) => {
            processMChatInstructions([instruction]);
          },
          handleTransferSessionStateUpdate: (
            sessionState: TransferSessionState,
          ) => {
            set((state) => ({
              transferSessionState: sessionState,
            }));

            if (sessionState.state === 'ended') {
              set((state) => ({
                config: {
                  ...state.config,
                  mode: 'normal',
                },
              }));
            }
          },
          handleResponse: (
            blocksToAdd: ChatBlock[],
            instructions: MChatInstruction[],
            extraPayload,
          ) => {
            const audioState = get().audioState;

            blocksToAdd = groupHyperlinks(blocksToAdd);
            blocksToAdd = _assignAvatars(blocksToAdd);

            if (
              (audioState === AudioState.Processing ||
                audioState === AudioState.Listening) &&
              blocksToAdd.length > 0 &&
              blocksToAdd[blocksToAdd.length - 1].direction === 'in'
            ) {
              set((state) => ({
                audioState: AudioState.Speaking,
              }));
            }

            if (extraPayload?.event) {
              processEvent(extraPayload?.event);

              if (
                extraPayload?.event === 'EndOfSpeech' &&
                extraPayload?.input
              ) {
                set((state) => ({
                  chatBlocks: [
                    ...state.chatBlocks,
                    {
                      type: 'message',
                      direction: 'out',
                      content: {
                        text: extraPayload?.input ?? '',
                      },
                    },
                  ],
                }));
              }
            }

            if (waitingForResponseTimeout) {
              clearTimeout(waitingForResponseTimeout);
              waitingForResponseTimeout = undefined;
            }

            const timestampDiff = Date.now() - lastMessageTimestamp;

            if (timestampDiff > MIN_DELAY) {
              blocksToAdd = _updateStreamedChatBlocks(blocksToAdd);
              const typing = getTypingIndicatorForLastChatBlocks(
                get().config.mode === 'streaming',
                blocksToAdd,
              );
              set((state) => ({
                waitingForResponse:
                  state.config.mode === 'streaming'
                    ? state.waitingForResponse
                    : false,
                typing,
                chatBlocks: [...state.chatBlocks, ...blocksToAdd],
              }));
              processMChatInstructions(instructions);
            } else {
              blocksToAdd = _updateStreamedChatBlocks(blocksToAdd);

              setTimeout(() => {
                const typing = getTypingIndicatorForLastChatBlocks(
                  get().config.mode === 'streaming',
                  blocksToAdd,
                );
                set((state) => ({
                  waitingForResponse:
                    state.config.mode === 'streaming'
                      ? state.waitingForResponse
                      : false,
                  typing,
                  chatBlocks: [...state.chatBlocks, ...blocksToAdd],
                }));
                processMChatInstructions(instructions);
              }, MIN_DELAY - timestampDiff);
            }
          },
          sendMessage: (
            textualInput: string,
            notShown: boolean = false,
            textToShow?: string,
            extraInput?: object,
            extraPayload?: object,
          ) => {
            const chatBlocksLength = get().chatBlocks.length;
            if (!notShown) {
              const input = textToShow ?? textualInput;

              const newBlock = {
                type: 'message',
                direction: 'out',
                timestamp: dayjs.utc().format(),
                content: {
                  text: input,
                },
              };

              const newBlocks = _assignAvatars([newBlock as TextChatBlock]);

              set((state) => ({
                chatBlocks: [...state.chatBlocks, newBlocks[0]],
              }));
            }

            set((state) => ({
              waitingForResponse: true,
              typing: true,
            }));

            resetUserInactivityTimeout();

            if (!waitingForResponseTimeout) {
              waitingForResponseTimeout = setTimeout(() => {
                const infoBlocks = assembleChatBlocksFromUserErrorMessage(
                  t('response_timeout_error_user_message'),
                );

                const textChatBlock = infoBlocks[0] as TextChatBlock;

                textChatBlock.avatar = get().avatars?.find(
                  (item) => item.type === 'robot',
                );

                set((state) => ({
                  waitingForResponse: false,
                  typing: true,
                  chatBlocks: [...state.chatBlocks, ...infoBlocks],
                }));
                waitingForResponseTimeout = undefined;
              }, get().config.responseTimeout);
            }

            lastMessageTimestamp = Date.now();

            if (get().config.mode !== 'liveChat') {
              if (!extraPayload && !chatBlocksLength && get().sessionValues)
                extraPayload = {
                  event: {
                    name: 'InitialValuesSetup',
                    data: {
                      ...get().sessionValues,
                    },
                  },
                };
              get().protocolManager.send(textualInput, {
                extraInput,
                extraPayload,
              });
            } else if (get().transferSessionState?.state === 'active') {
              get().transferController.transferSession?.send(textualInput);
            }
          },
          sendFeedback: (feedback: FeedbackInputFormat) => {
            get().protocolManager.sendFeedback(feedback);
          },
          setAudioState: (audioState) => {
            set((state) => ({
              audioState,
            }));
            resetUserInactivityTimeout();
          },
          setSessionValues: (sessionValues) => {
            set((state) => ({
              sessionValues,
            }));
          },
          setAvatars: (avatars) => {
            set((state) => ({
              avatars,
            }));
          },
          setComponentsCustomization: (
            componentsCustomization?: MChatComponentsCustomization,
          ) => {
            set((state) => ({
              componentsCustomization,
            }));
          },
          openWidget: () => {
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            const keepSession = get().keepSession;
            set((state) => ({
              widgetOpen: true,
              keepSession: false,
            }));

            void get().actions.connect(keepSession);
          },
          closeWidget: ({ keepSession = false } = {}) => {
            if (keepSession) {
              get().actions.refreshSession();
              set((state) => ({
                widgetOpen: false,
                keepSession: true,
              }));
              return;
            }

            if (userInactivityWarnTimeout) {
              clearTimeout(userInactivityWarnTimeout);
              userInactivityWarnTimeout = undefined;
            }

            if (userInactivityCloseTimeout) {
              clearTimeout(userInactivityCloseTimeout);
              userInactivityCloseTimeout = undefined;
            }

            get().protocolManager?.close();
            get().transferController?.transferSession?.close();

            set((state) => ({
              widgetOpen: false,
              openUserPromptPanel: undefined,
              chatBlocks: [],
              sessionId: null,
              protocolManager: undefined,
            }));
          },
          refreshSession: () => {
            set((state) => ({
              openUserPromptPanel: undefined,
            }));
            get().protocolManager.refreshSession();
            resetUserInactivityTimeout();
          },
          processCommand: (name, ...args) => {
            get().protocolManager.processCommand(
              name as ProtocolCommands,
              ...args,
            );
          },
          reset: () => {
            get().protocolManager.reset();
            set((state) => ({
              chatBlocks: [],
            }));
          },
          setAudioManager: (audioManager) => {
            set(() => ({
              audioManager,
            }));
          },
        },
      };
    },
    {
      name: 'mchat-storage',
      storage: createJSONStorage(() => sessionStorage),
      // partialize: () => {},
      partialize: (state) => ({
        chatBlocks: state.chatBlocks,
        openUserPromptPanel: state.openUserPromptPanel,
        lastUserActivityTimestamp: state.lastUserActivityTimestamp,
        widgetOpen: state.widgetOpen,
        keepSession: state.keepSession,
        sessionId: state.sessionId,
      }),
    },
  ),
);

const getTypingIndicatorForLastChatBlocks = (
  isStreamingMode: boolean,
  chatBlocks: ChatBlock[],
): boolean => {
  if (!isStreamingMode) {
    return false;
  }
  if (
    chatBlocks.length > 0 &&
    (chatBlocks[chatBlocks.length - 1].type !== 'message' ||
      (chatBlocks[chatBlocks.length - 1].type === 'message' &&
        !(chatBlocks[chatBlocks.length - 1] as TextChatBlock).streamed))
  ) {
    return true;
  } else {
    return false;
  }
};

export default useStore;
