import EventEmitter from 'eventemitter3';
import { type ChatBlock } from '../types';

interface MyClassEvents {
  close: (event: CloseEvent) => void;
  error: (event: Event) => void;
  response: (
    chatBlocks: ChatBlock[],
    extraPayload?: { input?: string; event?: string },
  ) => void;
}

export declare interface DialogConnector {
  _integrationKey: string;
  on: <U extends keyof MyClassEvents>(
    event: U,
    listener: MyClassEvents[U],
  ) => this;
}

export abstract class DialogConnector extends EventEmitter<
  keyof MyClassEvents
> {
  constructor(integrationKey?: string) {
    super();
    this._integrationKey = integrationKey ?? process.env.X_MCHAT_API_KEY;
    if (this.constructor === DialogConnector) {
      throw new Error(' Object of Abstract Class cannot be created');
    }
  }

  async connect(sessionId?: string): Promise<string> {
    throw new Error('Abstract Method has no implementation');
  }

  send(payload: object) {
    throw new Error('Abstract Method has no implementation');
  }

  refreshSession() {
    throw new Error('Abstract Method has no implementation');
  }

  close() {
    throw new Error('Abstract Method has no implementation');
  }
}
