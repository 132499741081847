export type OnClickAction = 'redirect' | 'emit_event' | 'open_in_new_tab';

export type UserPromptPanelType = 'UserPromptClosureCountdownPanel' | undefined;

export interface MessageChatBlockContent {
  text: string;
}

export interface SearchChatBlockContent {
  results: Array<{
    title?: string;
    link?: string;
    content: string;
    onClickAction: OnClickAction;
  }>;
}

export interface ButtonsChatBlockContent {
  prompt?: string;
  buttons: Array<{
    title: string;
    textualValue: string;
    extraValue?: object;
  }>;
}

export interface FeedbackChatBlockContent {
  kind: 'stars' | 'thumbs' | 'numeric_scale';
  text?: string;
  title?: string;
  compact: boolean;
  feedbackComment?: {
    title?: string;
    maxLength?: number;
    requestedUpTo?: number;
  };
  options?: {
    min: number;
    max: number;
    step: number;
  };
}

export interface HyperlinkChatBlockContent {
  url: string;
  preference: string;
  text: string;
  onClickAction: OnClickAction;
}

export interface HyperlinksGroupChatBlockContent {
  hyperlinks: HyperlinkChatBlockContent[];
}

export interface DebugChatBlockContent {
  rows: Array<{
    title: string;
    items: string[];
  }>;
}

export interface ImageChatBlockContent {
  source: string;
}

export type Direction = 'in' | 'out';

export interface TextChatBlock {
  direction: Direction;
  avatar?: Avatar;
  type: 'message';
  id?: string;
  streamed?: boolean;
  timestamp?: string; // e.g. 2019-03-06T09:11:55Z
  metadata?: ChatBlockMetadata;
  content: MessageChatBlockContent;
}

export interface ButtonsChatBlock {
  direction: Direction;
  type: 'buttons';
  content: ButtonsChatBlockContent;
}

export interface SearchChatBlock {
  direction: Direction;
  type: 'search';
  content: SearchChatBlockContent;
}

export interface HyperlinksGroupChatBlock {
  direction: Direction;
  type: 'hyperlinks-group';
  content: HyperlinksGroupChatBlockContent;
}

export interface HyperlinkChatBlock {
  direction: Direction;
  type: 'hyperlink';
  content: HyperlinkChatBlockContent;
}

export interface DebugChatBlock {
  direction: Direction;
  type: 'debug';
  content: DebugChatBlockContent;
}

export interface ImageChatBlock {
  direction: Direction;
  type: 'image';
  content: ImageChatBlockContent;
}

export interface FeedbackChatBlock {
  direction: Direction;
  type: 'feedback';
  content: FeedbackChatBlockContent;
}

export interface FeedbackInputFormat {
  rating?: number | 'up' | 'down';
  comment?: {
    text: string;
  };
}

export interface ChatBlockMetadata {
  className?: string;
}

export interface TimestampRowTemplateItem {
  type: 'timestamp';
  format: 'string';
}

export type RowTemplateItem = string | TimestampRowTemplateItem;

export type RowTemplate = RowTemplateItem[];

export type ChatBlock =
  | TextChatBlock
  | ButtonsChatBlock
  | SearchChatBlock
  | HyperlinkChatBlock
  | HyperlinksGroupChatBlock
  | ImageChatBlock
  | DebugChatBlock
  | FeedbackChatBlock;

interface MChatEvent {
  name: EventTypes;
}

export interface ChatInputActions {
  focus: () => void;
  setUserInput: (text: string) => void;
}

export interface MChatActions {
  open: () => void;
  focusInput: () => void;
  setUserInput: (text: string) => void;
}

export interface RedirectEvent extends MChatEvent {
  name: 'redirect';
  link: string;
}

export type EventTypes = 'redirect';

export type MChatEvents = RedirectEvent;

declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

export enum AudioState {
  Active = 0,
  Initiating = 1,
  Listening = 2,
  Processing = 3,
  Speaking = 4,
}

export type MChatTranslations = Record<'cs' | 'en', Record<string, string>>;
export type SessionValues = Record<string, string | number | boolean>;

export interface MChatComponentsCustomization {
  InBubbleHeader?: BubbleHeader;
  OutBubbleHeader?: BubbleHeader;
}

export interface BubbleHeader {
  position: 'inside';
  content: RowTemplate;
}

export interface MChatConfig {
  mode: 'normal' | 'streaming' | 'liveChat';
  language?: 'cs' | 'en';
  defaultOnClickAction?: OnClickAction;
  userInactivityTimeout: number;
  responseTimeout: number;
  userInactivityCountdownTime: number;
  transferToken?: string;
  maxUserInputLength: number;
  skipInitialMessage?: boolean;
}

export interface VoiceConfig {
  extension: string;
}

export type TargetEnvironment = 'dev' | 'prod';

export type MChatInstructionType = 'finishTurn' | 'transfer';

export type MChatTransferInstructionCommand =
  | 'verify_availability'
  | 'initiate';

export interface MChatTransferInstruction {
  name: 'transfer';
  command: MChatTransferInstructionCommand;
  data: object;
}

export type MChatInstruction =
  | {
      name: 'finishTurn';
    }
  | MChatTransferInstruction;

export interface Avatar {
  type: 'robot' | 'human';
  source: string;
  scope: 'user_message' | 'bot_message';
  position?: 'start' | 'center' | 'end';
  width?: string;
}
