import type { DialogConnector } from '../dialog-connectors/DialogConnector';
import type ProtocolMchatAdapter from '../protocol-mchat-adapters/ProtocolMchatAdapter';
import type {
  MChatInstruction,
  ChatBlock,
  MChatTransferInstruction,
  FeedbackInputFormat,
} from '../types';

import EventEmitter from 'eventemitter3';

export enum ProtocolCommands {
  debug = 'debug',
}

interface MyClassEvents {
  close: (event: CloseEvent) => void;
  error: (event: Event) => void;
  response: (
    chatBlocks: ChatBlock[],
    instructions: MChatInstruction[],
    extraPayload?: { input?: string; event?: string },
  ) => void;
  transfer: (instruction: MChatTransferInstruction) => void;
}

export declare interface ProtocolManager {
  on: <U extends keyof MyClassEvents>(
    event: U,
    listener: MyClassEvents[U],
  ) => this;
}

export abstract class ProtocolManager extends EventEmitter<
  keyof MyClassEvents
> {
  public _dialogConnector: DialogConnector;
  protected _protocolMchatAdapter: ProtocolMchatAdapter;
  public sessionId: string | undefined;

  constructor(
    dialogConnector: DialogConnector,
    protocolMchatAdapter: ProtocolMchatAdapter,
  ) {
    super();
    this._dialogConnector = dialogConnector;
    this._protocolMchatAdapter = protocolMchatAdapter;
    this._dialogConnector.on('response', (data: any) => {
      this.handleResponse(data);
    });
    this._dialogConnector.on('close', (data) => this.emit('close', data));
    this._dialogConnector.on('error', (data) => this.emit('error', data));
  }

  send(
    inputText: string,
    props: {
      extraPayload?: object;
      extraInput?: object;
    },
  ) {
    throw new Error('Abstract method has no implementation');
  }

  sendFeedback(feedbackResponse: FeedbackInputFormat) {
    throw new Error('Abstract method has no implementation');
  }

  refreshSession() {
    throw new Error('Abstract method has no implementation');
  }

  reset() {
    throw new Error('Abstract method has no implementation');
  }

  processCommand(name: ProtocolCommands, ...args: string[]) {
    const processors = this.getCommandProcessors(...args);
    if (!(name in processors)) {
      console.warn(`Command ${name} is not implemented by protocol manager.`);
      return;
    }
    processors[name]();
  }

  getCommandProcessors(...args: string[]): {
    [key in keyof typeof ProtocolCommands]: () => void;
  } {
    throw new Error('Abstract method has no implementation');
  }

  handleResponse(response: any): void {
    throw new Error('Abstract method has no implementation');
  }

  async connect() {
    return await this._dialogConnector.connect();
  }

  async reconnect(sessionId?: string) {
    return await this._dialogConnector.connect(sessionId);
  }

  close() {
    this._dialogConnector.close();
  }
}
