import {
  type ReactNode,
  type FC,
  useState,
  useEffect,
  useRef,
  useCallback,
  useMemo,
} from 'react';
import useStore from '../state';
import { Button } from './common/Buttons';

export const UserPromptPanel: FC<{ children: ReactNode }> = ({ children }) => {
  return <div className='mchat-user-prompt-panel'>{children}</div>;
};

export const UserPromptClosureCountdownPanel: FC = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const refreshSession = useStore((state) => state.actions.refreshSession);
  const closeWidget = useStore((state) => state.actions.closeWidget);
  const config = useStore((state) => state.config);
  const lastUserActivityTimestamp = useStore(
    (state) => state.lastUserActivityTimestamp,
  );
  const [now, setNow] = useState(Date.now());

  const handleConfirmation = useCallback(() => {
    refreshSession();
  }, [refreshSession]);

  const handleRejection = useCallback(() => {
    closeWidget();
  }, [closeWidget]);

  useEffect(() => {
    const interval = setInterval(() => {
      setNow(Date.now());
    }, 500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  useEffect(() => {
    setTimeout(() => {
      buttonRef.current?.focus();
    }, 10);
  }, [buttonRef?.current]);

  const remainingTime = useMemo(() => {
    const res =
      config.userInactivityTimeout - (now - lastUserActivityTimestamp) / 1000;
    if (res > 0) {
      return Math.round(res);
    }
    return 0;
  }, [now, lastUserActivityTimestamp]);

  return (
    <div className='mchat-user-prompt-panel'>
      <div className='mchat-user-prompt-panel__prompt'>
        <span className='mchat-primary-color mchat-text-with-icon'>
          <div className='mchat-time-left-icon'></div>
          <span>{renderRemainingTime(remainingTime)}</span>
        </span>
        <span>Přejete si pokračovat?</span>
      </div>
      <div className='mchat-user-prompt-panel__actions'>
        <Button
          className='mchat-action-button--secondary'
          ref={buttonRef}
          onClick={handleRejection}
        >
          Ne, to je vše
        </Button>
        <Button ref={buttonRef} onClick={handleConfirmation}>
          Ano, přeji
        </Button>
      </div>
    </div>
  );
};

const renderRemainingTime = (remainingTime: number) => {
  const minutes = Math.floor(remainingTime / 60).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });

  const seconds = (remainingTime % 60).toLocaleString(undefined, {
    minimumIntegerDigits: 2,
  });
  return `${minutes}:${seconds}`;
};
