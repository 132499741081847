import { useCallback, type SyntheticEvent } from 'react';
import { type OnClickAction, type MChatEvents } from '../types/index';
import useStore from '../state';

const MOBILE_BREAKPOINT = '700px';

function useOnLinkClick() {
  const config = useStore((state) => state.config);
  const closeWidget = useStore((state) => state.actions.closeWidget);

  const closeWidgetAndKeepSessionOnMobile = useCallback(() => {
    if (window.matchMedia(`(max-width: ${MOBILE_BREAKPOINT})`)?.matches) {
      closeWidget({ keepSession: true });
    }
  }, [closeWidget, window]);

  const onLinkClick = (
    url: string,
    event: SyntheticEvent,
    onEvent: (event: MChatEvents) => void,
    onClickAction?: OnClickAction,
  ) => {
    const action = onClickAction ?? config.defaultOnClickAction;

    switch (action) {
      case 'emit_event':
        event.preventDefault();
        closeWidgetAndKeepSessionOnMobile();

        onEvent?.({
          name: 'redirect',
          link: url,
        });
        break;
      case 'open_in_new_tab':
        event.preventDefault();
        closeWidgetAndKeepSessionOnMobile();
        window.open(url, '_blank', 'noopener,noreferrer');
        break;
    }
  };

  return onLinkClick;
}

export default useOnLinkClick;
