import EventEmitter from 'eventemitter3';

export type SessionStateType =
  | 'intiated'
  | 'acknowledged'
  | 'active'
  | 'disconnected'
  | 'ended';

export interface ActiveState {
  state: 'active';
  humanAgentTyping: boolean;
}

export interface HumanAgentMessage {
  text: string;
  user?: {
    gender: 'male' | 'female';
  };
}

export type TransferSessionState =
  | {
      state: SessionStateType;
    }
  | ActiveState;

export interface HumanAgentsState {
  available: boolean;
}

interface TransferSessionEvents {
  response: (messages: HumanAgentMessage[]) => void;
  stateUpdate: (state: TransferSessionState) => void;
}
export abstract class TransferController {
  public transferSession?: TransferSession;

  abstract transfer(data: object): Promise<TransferSession>;
  abstract verifyHumanAgentsAvailability(): Promise<HumanAgentsState>;
}

export declare interface TransferSession {
  on: <U extends keyof TransferSessionEvents>(
    event: U,
    listener: TransferSessionEvents[U],
  ) => this;
}

export abstract class TransferSession extends EventEmitter<
  keyof TransferSessionEvents
> {
  public sessionState?: TransferSessionState;

  abstract send(message: string): void;
  abstract close(): void;
}
