import React from 'react';
import { useCallback, type FC, type SyntheticEvent } from 'react';

import useOnLinkClick from '../../../hooks/useOnLinkClick';
import { type LinkButtonProps, LinkButton } from '../Buttons';
import type { MChatEvents, OnClickAction } from '../../../types';

interface HyperlinkButtonProps extends LinkButtonProps {
  url: string;
  onEvent?: (event: MChatEvents) => void;
  onClickAction?: OnClickAction;
}

export const HyperlinkButton: FC<HyperlinkButtonProps> = ({
  url,
  children,
  onClickAction,
  onEvent = () => {},
  ...rest
}) => {
  const onLinkClickHandler = useOnLinkClick();

  const onLinkClick = useCallback(
    (event: SyntheticEvent) => {
      onLinkClickHandler(url, event, onEvent, onClickAction);
    },
    [url, onClickAction, onEvent],
  );

  return (
    <LinkButton url={url} onClick={onLinkClick} {...rest}>
      {children}
    </LinkButton>
  );
};
