import urljoin from 'url-join';
import axios from 'axios';
import { DialogConnector } from './DialogConnector';

interface Response {
  data: object;
}

class MChannelsRestDialogConnector extends DialogConnector {
  baseURL: string;
  mchannelsBotId: string;

  private _axiosInstance: any;

  constructor(params: {
    integrationKey?: string;
    baseURL: string;
    mchannelsBotId: string;
  }) {
    const { integrationKey, baseURL, mchannelsBotId } = params;

    super(integrationKey);
    this.baseURL = baseURL;
    this.mchannelsBotId = mchannelsBotId;
  }

  async connect(): Promise<string> {
    this._axiosInstance = axios.create({
      baseURL: this.baseURL,
    });
    return await Promise.resolve('Ready to send REST calls.');
  }

  close() {}

  refreshSession() {}

  send(payload: object) {
    const headers: any = {};

    if (this._integrationKey) {
      headers['X-mchat-api-key'] = this._integrationKey;
    }

    const promise = this._axiosInstance.post(
      getMessageEndpointPath(this.baseURL, this.mchannelsBotId),
      payload,
      {
        headers,
      },
    );

    promise.then((response: Response) => {
      const { data } = response;
      this.emit('response', data);
    });
  }
}

const getMessageEndpointPath = (apiBase: string, botId: string) => {
  return urljoin(apiBase, 'bots', botId, 'message');
};

export default MChannelsRestDialogConnector;
